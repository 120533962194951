var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline-flex","margin-right":"10px"}},[_c('el-autocomplete',{attrs:{"fetch-suggestions":_vm.CheckAysnc,"trigger-on-focus":false,"popper-class":_vm.popperClass,"placeholder":_vm.placeholder,"highlight-first-item":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"adviceList"},[_c('div',{staticClass:"name",class:{
            Copyright: item.cat == 2,
            Language: item.cat == 5,
            Character: item.cat == 1,
            Author: item.cat == 3,
            General: item.cat == 0,
            Meta: item.cat == 4,
            Soundtrack: item.cat == 6,
          },domProps:{"innerHTML":_vm._s(item.tag || _vm.ConvertLangRes(item.langs))}}),_c('div',{staticClass:"addr"},[_vm._v(_vm._s(item.cnt))])])]}}]),model:{value:(_vm.newValue),callback:function ($$v) {_vm.newValue=$$v},expression:"newValue"}},[(_vm.newValue)?[(_vm.loading)?[_c('i',{staticClass:"el-input__icon el-icon-loading",staticStyle:{"color":"#e6a23c"},attrs:{"slot":"suffix"},slot:"suffix"})]:[(_vm.nouse)?_c('i',{staticClass:"el-input__icon el-icon-success",staticStyle:{"color":"#67c23a"},attrs:{"slot":"suffix"},slot:"suffix"}):_c('i',{staticClass:"el-input__icon el-icon-error",staticStyle:{"color":"#f56c6c"},attrs:{"slot":"suffix"},slot:"suffix"})]]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }